import React from "react"
import Banner from "../components/Banner/banner"

const Thanks = () => (
  <>
      <Banner
        h1Txt={"Saime sinu sinu sõnumi kätte!"}
        h2Txt={
          "Vastame Teile esimesel võimalusel!"
        }
        btnTxt={"Mine tagasi avalehele"}
				btnLink={"/"}
      />
  </>
)

export default Thanks
